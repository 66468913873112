import React from "react";
import { Box } from "rebass/styled-components";
import Container from "../components/UI/Container";
import Layout from "../components/Layout";
import { useStaticQuery, graphql } from "gatsby";
import Markdown from "../components/UI/Markdown";

const Confidentialite = props => {
  const { markdownRemark } = useStaticQuery(
    graphql`
      query {
        markdownRemark(frontmatter: { title: { eq: "legals" } }) {
          frontmatter {
            name
            url
            address
            publication
            url
            mail
            phone
            confidential
          }
        }
      }
    `
  );
  const legals = markdownRemark.frontmatter || {};
  for (const id in legals) {
    if (id !== "confidential") {
      var re = new RegExp("{" + id + "}", "g");
      legals.confidential = legals.confidential.replace(re, legals[id]);
    }
  }
  return (
    <Layout title="Politique de confidentialité" slug="confidentialite" {...props}>
      <Box py={10}>
        <Container>
          <Markdown>{legals.confidential}</Markdown>
        </Container>
      </Box>
    </Layout>
  );
};

export default Confidentialite;
